import React from "react"
import AppBarNew from "../../components/Appbar"
import Footer from "../../components/Footer"
import img1 from "../../../images/Main/downloadapp image.png"
import playstore from "../../../images/Playstore/download-on-the-app-store-apple-logo-svgrepo-com.svg"
import Logo from "../../../images/LOGO/Group 2639.svg"
import "./DownloadApp.css"
import Head from "../../components/Head"

function DownloadApp() {
  return (
    <>
      <Head pageTitle="Download App" />
      <AppBarNew />
      <img src={img1} className="img1" alt="" />
      <div className="img2">
        <img
          src={Logo}
          style={{
            width: "100px",
            height: "145px",
          }}
          alt=""
        />
      </div>
      <div>
        <p className="line1">Racqy: Find New Activities & Friends</p>
      </div>
      <img src={playstore} className="playstore" alt="" />
      <br />
      <Footer />
    </>
  )
}

export default DownloadApp
